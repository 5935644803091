<template>
  <VueDraggableResizable :parent="true" :resizable="isEditable" :draggable="isEditable"
                         :x="xAnnotation"
                         :y="yAnnotation"
                         :w="wAnnotation"
                         :h="hAnnotation"
                         :grid="[10, 10]"
                         class="dragableAnnotationComponent"
                         :class-name="isEditable?'defaultClassDragAnnotation':'clickAnnotation'"
                         :class-name-dragging="isEditable?'onDragAnnotation':''"
                         @resizing="onResizeAnnotation"
                         @dragging="onDragAnnoation" @onDrag="onDragInProgress"
                         :z="30" :disableUserSelect="false">
    <transition name="fade">
      <!-- ANNOTATION TEXT -->
      <div class="annotationPosition" v-show="show"
           :id="'annotation-'+annotation.annotationID">
        <div class="annotation" :class="{editable:isEditable}" v-if="isText"
             :id="'annotation-'+annotation.annotationID"
             v-show="show" @click="selectAnnotation">
          <transition name="fade">
            <div class="annotationTextContainer"
                 @focusin="onStartEditing"
                 @focusout="validateAnnotationContent"
                 :placeholder="$t('createWeetComponent.advancedEditing.annotationMode.edit.placeholder').toString()"
                 v-show="show" :ref="'annotation-'+annotation.annotationID" :contenteditable="isEditable"
            v-html="annotation.textContent"></div>
          </transition>
        </div>
      </div>

      <!-- ANNOTATION IMAGE -->
      <!--      <div class="annotation annotationImage" v-if="isImage" :id="'annotation-'+annotation.annotationID"-->
      <!--           v-show="show" :class="{left:isLeft,right:isRight,top:isTop,bottom:isBottom}" @click="selectAnnotation">-->
      <!--        <div class="containerUploadElement">-->
      <!--          <img class="imageContent" :src="urlSourceImage" />-->
      <!--          &lt;!&ndash;          <img class="imageContent" src="https://cloudfront-us-east-2.images.arcpublishing.com/reuters/J63FBX3CCJJQFKNN7UGBKUOCGA.jpg" />&ndash;&gt;-->
      <!--          &lt;!&ndash;        <img class="imageContent" src="https://i.pinimg.com/564x/5b/b6/6d/5bb66dd78e4cd8009a6a947fe64e21c8.jpg" />&ndash;&gt;-->
      <!--          <b-upload v-if="isEditable" class="uploadElement" :class="{'empty':!urlSourceImage}" drag-drop accept=".png,.jpeg,.jpg,.gif" @input="uploadNewImage">-->
      <!--            <div class="uploadPanel">-->
      <!--              <w-icon color="dark"-->
      <!--                      icon="upload"-->
      <!--                      size="large">-->
      <!--              </w-icon>-->
      <!--              <p class="textUpload">{{$t('createWeetComponent.advancedEditing.annotationMode.edit.placeholderUpload')}}</p>-->
      <!--            </div>-->
      <!--          </b-upload>-->
      <!--        </div>-->

      <!--      </div>-->
    </transition>
  </VueDraggableResizable>

</template>

<script lang="ts">
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import {Annotation, AnnotationType} from "@/store/annotation/annotationModel";
import VueDraggableResizable from 'vue-draggable-resizable';
import {Howl} from 'howler';
import UserAvatar from "@/components/user/UserAvatar.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import store from "@/store";
import {SELECT_ANNOTATION, UPDATE_ANNOTATION} from "@/store/annotation/annotationAction";
import {Weet} from "@/store/weet/weetModel";
import CenterCenterPanel from "@/components/layout/CenterCenter.vue";
import IconsSelector from "@/components/icons/IconsSelector.vue";
import WModal from "@/components/wrapper/w-modal.vue";
import {PUBLIC_PATH} from "@/store/conf/api";
import {getImageURLForSize, htmliFy} from "@/utils/util";
import {PLAYER_PLAYING} from "@/store/timeLine/timeLineAction";
import debounce from "lodash/debounce";
import {clone} from "@/utils/cloneUtil";
import {ANNOTATION_INTERACTION_SAVE_IN_PROGRESS} from "@/store/recordingState/recordStateAction";
import delay from "delay";
import {exportNode2PngDataURL} from "@/utils/ImageUtil";
import {h} from "vue";
import fitty from "fitty";
import {FittyInstance} from "fitty";
import {inform, informError} from "@/utils/dialog";

@Component({
  methods: {h},
  components: {
    WModal, IconsSelector, CenterCenterPanel, WIcon, UserAvatar,
    VueDraggableResizable
  },
})
export default class AnnotationElementPlayerComponent extends Vue {

  private pop = new Howl({
    src: [PUBLIC_PATH + 'sound/pop.mp3'],
    volume: 0.80,
  });


  @Prop()
  private parentID!: string;

  @Prop()
  private annotation!: Annotation;
  @Prop()
  private weet!: Weet;
  @Prop({default: 0})
  private timePlayer!: number;

  @Prop({default:false})
  private miniMode!:boolean


  private annotationContainer!: HTMLDivElement;
  private fittyInstance!: FittyInstance;

  private ready: boolean = false;

  private xAnnotation = 0;
  private yAnnotation = 0;
  private wAnnotation = 1;
  private hAnnotation = 1;
  private dragInProgress = false;
  private parent!: HTMLElement;
  private textContent="";

  // DEBOUCE METHODS
  private debounceOnDrag = debounce((el: any) => {
    el.dragInProgress = false;
  }, 1000);

  private debounceSaveAnnotation = debounce((annotation) => {
    this.updateAnnotation(annotation, false);
  }, 1500);
  private debounceResize = debounce(async (el) => {
    this.reinitPosition()
  }, 250);


  // GETTER

  get annotationContent() {
    return htmliFy(this.annotation.textContent);
  }

  get isText() {
    return this.annotation.type === AnnotationType.TEXT;
  }

  get isImage() {
    return this.annotation.type === AnnotationType.IMAGE;
  }

  get isSaveInProgress(){
    return store.getters.isAnnotationInteractionSaveInProgress;
  }

  get show() {
    return this.ready && this.timePlayer >= this.annotation.time &&
        this.timePlayer <= this.annotation.time + this.annotation.duration;
  }

  get isEditable() {
    if (store.getters.getAnnotationSelected) {
      return store.getters.getAnnotationSelected.annotationID === this.annotation.annotationID
          && store.getters.isAnnotationMode;
    }
    return false;
  }

  mounted() {
    this.ready = true;
    // this.pop.play();
    this.initExternalComponent()
    window.addEventListener('resize', this.onResize)
    this.textContent=this.annotation.textContent;
    // setup the annotationContainer
    this.annotationContainer = this.$refs['annotation-' + this.annotation.annotationID] as HTMLDivElement;
    this.fittyInstance = fitty(this.annotationContainer, {
      minSize: 6,
      maxSize: 72,
    })

    this.onResize()


  }

  private setupAnotationWithPourcentage(x, y, w, h) {
    this.xAnnotation = this.parent.offsetWidth * x / 100;
    this.yAnnotation = this.parent.offsetHeight * y / 100;
    this.wAnnotation = this.parent.offsetWidth * w / 100;
    this.hAnnotation = this.parent.offsetHeight * h / 100;
  }

  private initExternalComponent() {
    const el = document.getElementById(this.parentID);
    if (el) {
      this.parent = el;
    } else {
      console.warn("Error no parent found for interaction")
    }
  }

  private selectAnnotation() {
    if (store.getters.isAnnotationMode) {
      store.dispatch(SELECT_ANNOTATION, this.annotation);
    }
  }

  private onDragAnnoation(left, top) {
    this.xAnnotation = left;
    this.yAnnotation = top;
    this.updateAnnotationPosition()
    this.onDragInProgress();
  }

  private onResizeAnnotation() {
    this.fittyInstance.fit();
  }

  private async onResize() {
    this.debounceResize(this);
  }

  private async reinitPosition() {
    this.setupAnotationWithPourcentage(this.annotation.positionX, this.annotation.positionY, 20, 20)
    this.fittyInstance.fit();
  }


  private updateAnnotationPosition() {
    const x = this.xAnnotation / this.parent.offsetWidth * 100;
    const y = this.yAnnotation / this.parent.offsetHeight * 100;

    const annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
    annotationUpdated.positionX = x;
    annotationUpdated.positionY = y;
    this.debounceSaveAnnotation(annotationUpdated);
  }

  private onDragInProgress() {
    store.dispatch(PLAYER_PLAYING, false);
    this.dragInProgress = true;
    //inform the drag is ended
    this.debounceOnDrag(this);
  }

  private async saveAnnotation(){
    if(this.isSaveInProgress){
      return;
    }
    var annotationUpdate = clone(store.getters.getAnnotationSelected) as Annotation;
    annotationUpdate.textContent=this.textContent;
    await this.updateAnnotation(annotationUpdate,true);
  }

  private async updateAnnotation(annotationUpdate: Annotation,withPicture) {
    // export img before update
    let dataUrlImage = '';
    await store.dispatch(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS, true);
    if (withPicture) {
      const interactionCardNode = document.getElementById('annotation-' + this.annotation.annotationID);
      if (!interactionCardNode) {
        return;
      }
      // wait for annotation update
      await delay(50);
      dataUrlImage = await exportNode2PngDataURL(interactionCardNode);
      annotationUpdate.exportStaticUrl = dataUrlImage;
    }
    await store.dispatch(UPDATE_ANNOTATION, {
      weetID: this.weet.weetID, annotation: annotationUpdate
    }).then((backendAnnotation) => {
      store.dispatch(SELECT_ANNOTATION, backendAnnotation)
      inform(this.$t('createWeetComponent.advancedEditing.annotationMode.edit.success').toString());
      store.dispatch(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS,false);
    }).catch(() => {
      informError(this.$t('createWeetComponent.advancedEditing.annotationMode.edit.error').toString());
      store.dispatch(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS,false);
    })
  }

  private onStartEditing(){
    this.$emit('onEdit',true)
  }
  private validateAnnotationContent(event: InputEvent) {
    this.$emit('onEdit',false)
    if (event.target != null) {
      const element = event.target as HTMLElement;
      if (htmliFy(element.innerText).trim() !== this.annotation.textContent) {
        this.textContent=htmliFy(element.innerText).trim();
        this.saveAnnotation();
      }
    }
  }

  beforeDestroy() {
    this.fittyInstance.unsubscribe();
    window.removeEventListener('resize', this.onResize);
  }

}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.dragableAnnotationComponent {
  position: absolute;
}

.annotationPosition {
  position: absolute;
  z-index: 30;
  height: 100%;
  width: 100%;
  transition: width,height 0.2s ease;
  // reajust coordonate
  //margin-left: -20px;
  //margin-top: -20px;
  animation-duration: 0.5s;

  .annotation {
    height: 100%;
    width: 100%;
    pointer-events: auto;
    background: white;
    border: solid 2px white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.editable {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
    }

    .annotationTextContainer {
      padding: 16px;
      animation-delay: 0.1s;

      &[placeholder]:empty::before {
        content: attr(placeholder);
        color: var(--light1);
      }

      &[placeholder]:empty:focus::before {
        content: "";
      }
    }
  }
}


</style>

<style lang="scss">
.uploadElement {
  .upload-draggable {
    border: 0px;
    height: 100%;
    border-radius: 12px;

  }
}

.defaultClassDragAnnotation {
  background-color: transparent;
  border: none;
  cursor: grab;

  .handle {
    pointer-events: all;
  }
}

.onDragAnnotation {
  cursor: move;
  opacity: 0.8;
}

.clickAnnotation {
  cursor: pointer;
}
</style>
