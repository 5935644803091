<template>
  <transition name="zoom">
    <perfect-scrollbar v-show="selectedAnotation" v-if="selectedAnotation"
                       class="interactionListOptionContainer"
                       :options="{suppressScrollX:true}"
                       ref="scrollPanelOption">
      <div class="optionLineTitle">
        {{ $t('createWeetComponent.advancedEditing.interactionMode.option.title') }}
      </div>
      <!-- Start -->
      <div class="optionLine">
        <div class="labelOption">
          {{
            $t('createWeetComponent.advancedEditing.interactionMode.option.start')
          }}

        </div>
        <div class="labelTimeInput">
          <transition name="fade">
            <div class="timeHelper" @click="setStart(timePlayer/1000)" v-show="showStartHelper">
              {{
                $t('createWeetComponent.advancedEditing.interactionMode.option.setButton', {time: getHumanTime(timePlayer)})
              }}
            </div>
          </transition>
          <w-input class="timeInput" @focus="showStartHelper=true"
                   @blur="setStart" size="small"
                   :value="getHumanTime(selectedAnotation.time,1)"
                   :validate-on-enter="true"/>
        </div>

        <!-- End -->
        <div class="labelOption">
          {{
            $t('createWeetComponent.advancedEditing.interactionMode.option.end')
          }}
        </div>
        <div class="labelTimeInput">
          <transition name="fade">
            <div class="timeHelper" @click="setEnd(timePlayer/1000)"
                 v-show="showDurationHelper && isDurationButtonAivalable">
              {{
                $t('createWeetComponent.advancedEditing.interactionMode.option.setButton', {time: getHumanTime(timePlayer)})
              }}
            </div>
          </transition>
          <w-input class="timeInput" @focus="showDurationHelper=true"
                   @blur="setEnd"
                   size="small"
                   :value="getHumanTime(selectedAnotation.time+selectedAnotation.duration,1)"
                   :validate-on-enter="true"/>
        </div>
      </div>
      <!-- duration -->
      <div class="optionLine"
           :class="{marginHelper:showStartHelper||(showDurationHelper && isDurationButtonAivalable)}">
        <div class="labelOption">
          {{
            $t('createWeetComponent.advancedEditing.interactionMode.option.duration')
          }}
        </div>
        <div class="labelTimeInput">
          <w-input class="timeInput" @focus="showDurationHelper=true"
                   @blur="setDuration"

                   size="small"
                   :value="getHumanTime(selectedAnotation.duration,1)"
                   :validate-on-enter="true"/>
        </div>
      </div>
    </perfect-scrollbar>
  </transition>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import WButton from "@/components/wrapper/w-button.vue";
import store from "@/store";
import prettyMilliseconds from "pretty-ms";
import AnnotationListElement from "@/components/recorder/annotation/AnnotationListElement.vue";
import {Annotation, AnnotationPosition, AnnotationTheme, AnnotationType} from "@/store/annotation/annotationModel";
import {
  CREATE_ANNOTATION,
  DELETE_ANNOTATION,
  SELECT_ANNOTATION,
  UNSELECT_ANNOTATION,
  UPDATE_ANNOTATION
} from "@/store/annotation/annotationAction";
import WSwitch from "@/components/wrapper/w-switch.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import {confirmationDanger, inform, informError} from "@/utils/dialog";
import WModal from "@/components/wrapper/w-modal.vue";
import CenterCenter from "@/components/layout/CenterCenter.vue";
import IconsSelector from "@/components/icons/IconsSelector.vue";
import {exportNode2PngDataURL} from "@/utils/ImageUtil";
import CreateAnnotationButton from "@/components/recorder/button/CreateAnnotationButton.vue";
import WInput from "@/components/wrapper/w-input.vue";
import {SEEK_PLAYER} from "@/store/timeLine/timeLineAction";
import {clone} from "@/utils/cloneUtil";


@Component({
  components: {
    WInput,
    CreateAnnotationButton,
    IconsSelector, CenterCenter, WModal, WIcon, WSwitch, AnnotationListElement, WButton
  },
})
export default class AnnotationDurationHelper extends Vue {

  @Prop()
  private weet!: Weet;

  private showDurationHelper: boolean = false;
  private showStartHelper: boolean = false;

  get timePlayer(): number {
    return store.getters.getTimerPlayer
  }

  get selectedAnotation(): Annotation | undefined {
    return store.getters.getAnnotationSelected;
  }

  get isDurationButtonAivalable(): boolean {
    var annot = this.selectedAnotation;
    if (!annot) {
      return false;
    }
    var endPosition = this.timePlayer;
    return annot.time + 1000 < endPosition;
  }

  private getHumanTime(val: number, digit: number = 0): string {
    if (!val || val < 0) {
      return '0:00';
    } else {
      return prettyMilliseconds(val, {colonNotation: true, secondsDecimalDigits: digit});
    }
  }

  private stringToSecond(value: string): number {
    value = value + ''.trim();
    if (value.split(":").length === 2) {
      // @ts-ignore (ignore because isNaN accepter string... )
      if (isNaN(value.split(":")[0]) || isNaN(value.split(":")[1])) {
        throw new Error("Not a number");
      }
      var minute = parseFloat(value.split(":")[0]);
      var second = parseFloat(value.split(":")[1]);

      return minute * 60 + second;
    }
    // @ts-ignore
    if (isNaN(value)) {
      throw new Error("Not a number");
    }
    return parseFloat(value);
  }

  private setStart(value: string | number) {
    this.showStartHelper = false;
    try {
      var startPosition = this.stringToSecond(value + "") * 1000;
      if (startPosition === store.getters.getAnnotationSelected.time) {
        return;
      }
      if (startPosition >= 0) {
        var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
        annotationUpdated.time = startPosition;
        if (annotationUpdated.time < 500) {
          annotationUpdated.time = 500;
        }
        this.$emit('onUpdateAnnotation', annotationUpdated);
        store.dispatch(SEEK_PLAYER, annotationUpdated.time)
      }
    } catch (e) {
      informError(this.$t("createWeetComponent.advancedEditing.annotationMode.option.durationError").toString());
    }
  }

  private setEnd(value: string | number) {
    this.showDurationHelper = false;
    try {
      var endPosition = this.stringToSecond(value + "") * 1000;
      var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
      var newDuration = endPosition - annotationUpdated.time;
      if (newDuration === annotationUpdated.duration) {
        return;
      }
      annotationUpdated.duration = newDuration;
      if (annotationUpdated.duration < 1000) {
        annotationUpdated.duration = 1000;
      }
      this.$emit('onUpdateAnnotation', annotationUpdated);
    } catch (e) {
      informError(this.$t("createWeetComponent.advancedEditing.annotationMode.option.durationError").toString());
    }
  }

  private setDuration(value: string | number) {
    this.showDurationHelper = false;
    try {
      var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
      const newDuration=this.stringToSecond(value + "") * 1000;
      if (newDuration === annotationUpdated.duration) {
        return;
      }
      annotationUpdated.duration = newDuration;
      if (annotationUpdated.duration < 1000) {
        annotationUpdated.duration = 1000;
      }
      this.$emit('onUpdateAnnotation', annotationUpdated);
    } catch (e) {
      informError(this.$t("createWeetComponent.advancedEditing.annotationMode.option.durationError").toString());
    }
  }

}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';


.interactionListOptionContainer {
  animation-duration: 0.2s;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 40px;
  .optionLineTitle {
    margin-top: 8px;
    font-weight: 900;
    color: var(--black);
    font-size: 18px;
  }
}

.optionLine {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
  transition: all 0.5s ease-in-out;

  &.marginHelper {
    margin-top: 24px;
  }

  .labelOption {
    font-size: 14px;
    color: var(--black);
  }

  .labelTimeInput {
    position: relative;

    .timeInput {
      margin-left: 8px;
      margin-right: 8px;
      width: 70px;

      .input {
        text-align: right;
      }
    }

    .timeHelper {
      animation-duration: 0.5s;
      animation-delay: 0.1s;
      position: absolute;
      font-size: 12px;
      margin-top: 32px;
      color: var(--primary);
      width: 100%;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>
