import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import './scss/themes/main.scss';
import './scss/global.scss';
import Meta from 'vue-meta';
import * as Sentry from '@sentry/browser';
import {Vue as VueIntegration} from '@sentry/integrations';
import {log} from '@/utils/log';
import InfiniteLoading from 'vue-infinite-loading';
import {i18n} from '@/utils/i18nUtils';
import VueSocialSharing from 'vue-social-sharing';
import amplitude from 'amplitude-js';
import {getDeviceID, setVersion} from '@/utils/tracker';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueNativeNotification from 'vue-native-notification';
import {initFirebasePushNotification} from '@/utils/firebase';
import * as Msal from '@azure/msal-browser';
import {msalConfig} from '@/utils/externalSignin';
import {loadTheme, Theme} from "@/theme";
import {isSpeach} from "@/utils/speachUtils";

// import here because doesnet work in local importation
Vue.use(InfiniteLoading, { /* options */});
Vue.config.productionTip = false;

// Buefy
Vue.use(Buefy, {});

// use native notification
Vue.use(VueNativeNotification, {
    // Automatic permission request before
    // showing notification (default: true)
    requestOnNotify: true
});
Vue.config.errorHandler = (err, vm, info) => {
    console.error(`Error: ${err.toString()}\nInfo: ${info}`);
    console.error(err);
};

// scrollbar
Vue.use(PerfectScrollbar);

// User meta for generate social tag
Vue.use(Meta);
// set log level
log.setLevel((process.env.VUE_APP_LOG_LEVEL || 'info'));

// module social sharing
Vue.use(VueSocialSharing);


// Vue.component('WToolTip', WToolTip);
// declare AuthModule
declare module 'vue/types/vue' {
    interface Vue {
        $notification: any;
        $messaging: any;
    }

    interface VueConstructor {
        $msalObj: any;
    }
}


if (!isSpeach()) {
    /**
     * This is example for request and response interceptors for axios library
     */
    if (process.env.VUE_APP_ENV !== 'DEV') {
    // declare sentry
        // @ts-ignore
        Sentry.init({
            environment: process.env.VUE_APP_ENV,
            dsn: process.env.VUE_APP_SENTRY,
            integrations: [new VueIntegration({Vue, attachProps: true})],
        });
        Sentry.configureScope((scope) => {
            if (store.getters.isAuthenticated) {
                scope.setUser(
                    {
                        username: store.getters.userConnected.login,
                        id: store.getters.userConnected.userID,
                        email: store.getters.userConnected.contactEmail,
                    },
                );
            }
        });
    }


    // amplitude setting
    amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_KEY || '', undefined,
        {includeReferrer: true, includeUtm: true, deviceId: getDeviceID()});
    setVersion();


    // Init firebase settings for push notification
    try {
        // @ts-ignore
        Vue.$messaging = initFirebasePushNotification();
    } catch (e) {
        // Catch error to prevent the crash of the  whole application if firebase initialization is KO
        log.error(e);
    }

    try {
        Vue.$msalObj = new Msal.PublicClientApplication(msalConfig);
    } catch (error) {
        // If it throw an error, then we just ignore it since we will try to init it inside the ExternalSignin component
        // The error probably come from google login with redirection
        log.error(error);
    }
}


const app = new Vue({
    router,
    store,
    render: (h) => h(App),
    i18n
}).$mount('#app');

// load default theme when load the page
loadTheme(Theme.DEFAULT);

// @ts-ignore
if (window.Cypress) {
    // only available during E2E tests
    // @ts-ignore
    window.app = app;
}
