<template>
  <div class="weetAdvancedEditingInteractionContainer">
    <div class="colContainer">
      <div class="colLeft">
        <perfect-scrollbar class="interactionListContainer" :options="{suppressScrollX:true}" ref="scrollPanel"
                           v-if="listOfAnnotation.length>0">

          <w-button class="buttonAddList" color="outlined" :expanded="true" size="small" icon="cursor-default-click-outline" @click="createInteraction">
            {{ $t('createWeetComponent.advancedEditing.interactionMode.add') }}
          </w-button>
          <transition-group name="list-complete">
            <!-- list of interaction -->
            <AnnotationListElement class="list-complete-item" :weet="weet" :annotation="annot"
                                   v-for="annot in listOfAnnotation" style="transition: transform 1s"
                                   :key="annot.annotationID+'-annot'"
            @delete="deleteInteraction"/>
          </transition-group>
        </perfect-scrollbar>

        <!-- Empty state interaction -->
        <div v-else class="interactionListContainer emptyState"> <!-- EMPTY STATE -->
          <div class="emptyStateTitle">
            {{ $t('createWeetComponent.advancedEditing.interactionMode.emptyState.title') }}
          </div>
          <div class="emptyStateDescription">
            {{ $t('createWeetComponent.advancedEditing.interactionMode.emptyState.description') }}
          </div>
          <w-button class="buttonAddList" color="outlined" :expanded="true" size="small" icon="cursor-default-click-outline"
          @click="createInteraction">
            {{ $t('createWeetComponent.advancedEditing.interactionMode.add') }}
          </w-button>
        </div>
      </div>
      <div class="colMiddle">
        <AnnotationDurationHelper @onUpdateAnnotation="updateAnnotation" />
      </div>
      <div class="colRight">
        <w-button icon="check" class="buttonSave" :loading="loading|| isAnnotationInteractionSaveInProgress" @click="back">
          {{ $t('createWeetComponent.advancedEditing.validate') }}
        </w-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import WButton from "@/components/wrapper/w-button.vue";
import store from "@/store";
import prettyMilliseconds from "pretty-ms";
import AnnotationListElement from "@/components/recorder/annotation/AnnotationListElement.vue";
import {Annotation, AnnotationPosition, AnnotationTheme, AnnotationType} from "@/store/annotation/annotationModel";
import {
  CREATE_ANNOTATION,
  DELETE_ANNOTATION,
  SELECT_ANNOTATION,
  UNSELECT_ANNOTATION,
  UPDATE_ANNOTATION
} from "@/store/annotation/annotationAction";
import WSwitch from "@/components/wrapper/w-switch.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import {confirmationDanger, inform, informError} from "@/utils/dialog";
import WModal from "@/components/wrapper/w-modal.vue";
import CenterCenter from "@/components/layout/CenterCenter.vue";
import IconsSelector from "@/components/icons/IconsSelector.vue";
import {exportNode2PngDataURL} from "@/utils/ImageUtil";
import CreateAnnotationButton from "@/components/recorder/button/CreateAnnotationButton.vue";
import WInput from "@/components/wrapper/w-input.vue";
import {SEEK_PLAYER} from "@/store/timeLine/timeLineAction";
import AnnotationDurationHelper from "@/components/recorder/annotation/AnnotationDurationHelper.vue";


@Component({
  components: {
    AnnotationDurationHelper,
    WInput,
    CreateAnnotationButton,
    IconsSelector, CenterCenter, WModal, WIcon, WSwitch, AnnotationListElement, WButton
  },
})
export default class CreateWeetInteractionMode extends Vue {

  @Prop()
  private weet!: Weet;

  private loading: boolean = false;


  get timePlayer(): number {
    return store.getters.getTimerPlayer
  }

  get isAnnotationInteractionSaveInProgress(){
    return store.getters.isAnnotationInteractionSaveInProgress;
  }

  get listOfAnnotation():Annotation[]{
    return this.weet.annotations.filter(a=>a.type===AnnotationType.INTERACTION).sort((a,b)=>{
      if(a.time<b.time){
        return -1;
      }else{
        return 1
      }
    })
  }

  private back() {
    this.$emit('back');
  }

  private getHumanTime(val: number,digit:number=0): string {
    if (!val || val < 0) {
      return '0:00';
    } else {
      return prettyMilliseconds(val, {colonNotation: true, secondsDecimalDigits: digit});
    }
  }

  private async createInteraction() {
    const annotation = new Annotation();
    annotation.type = AnnotationType.INTERACTION;
    annotation.textContent = "";
    if(this.timePlayer<0){
      annotation.time = 0;
    }else {
      annotation.time = this.timePlayer;
    }
    if(annotation.time<500){
      annotation.time=500;
    }
    annotation.duration = 7000;
    annotation.positionType = AnnotationPosition.FREE;
    annotation.theme = AnnotationTheme.DEFAULT;
    annotation.iconMD = '';
    annotation.profil = false;
    annotation.interactionUrl = "";
    annotation.user = null;
    annotation.sound = false;
    annotation.positionX=20;
    annotation.positionY=20;
    annotation.annotationCard.alwaysShow=true;
    var annotationCreated=await store.dispatch(CREATE_ANNOTATION, {weetID: this.weet.weetID, annotation: annotation});
    store.dispatch(SELECT_ANNOTATION,annotationCreated);
    store.dispatch(SEEK_PLAYER, annotation.time)
  }



  private async updateAnnotation(annot: Annotation, withPicture = false) {
    this.loading = true;
    // export img before update
    var dataUrlImage = '';
    if (withPicture) {
      const annotationNode = document.getElementById('annotation-' + annot.annotationID);
      if (!annotationNode) {
        return;
      }
      dataUrlImage = await exportNode2PngDataURL(annotationNode);
      annot.exportStaticUrl = dataUrlImage;
    }

    await store.dispatch(UPDATE_ANNOTATION, {
      weetID: this.weet.weetID, annotation: annot
    }).then(() => {
      store.dispatch(SELECT_ANNOTATION, annot)
      inform(this.$t('createWeetComponent.advancedEditing.annotationMode.edit.success').toString());
      this.loading = false;
    })
  }

  private deleteInteraction(interaction:Annotation){
    try {
      confirmationDanger(this.$t('createWeetComponent.advancedEditing.interactionMode.delete.confirmMessage').toString(),
          this.$t('createWeetComponent.advancedEditing.interactionMode.delete.confirmTitle').toString()).then(() => {
        store.dispatch(DELETE_ANNOTATION, {weetID: this.weet.weetID, annotation: interaction});
        store.dispatch(UNSELECT_ANNOTATION);
      })
    }catch(e){
      // nothing to do
    }
  }

  beforeDestroy() {
    store.dispatch(UNSELECT_ANNOTATION);
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.weetAdvancedEditingInteractionContainer {
  margin: auto;
  height: 100%;

  .colContainer {
    display: flex;
    width: 100%;
    height: 100%;

    .colLeft {
      background: var(--light);
      min-width: 320px;
      max-width: 320px;
      text-align: left;
      height: 100%;
      animation-duration: 0.2s;
      //position: relative;

      .interactionListContainer {
        width: 100%;
        height: 100%;
        max-height: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;

        .buttonAddList {
          text-align: center;
          margin-top: 8px;
        }
      }
    }

    .colMiddle {
      text-align: left;
      min-width: 320px;
      max-width: 320px;
      height: 100%;
    }

    .colRight {
      .buttonSave {
        position: absolute;
        right: 16px;
        bottom: 16px;
      }
    }
  }

  // EMPTY STATE
  .emptyState {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    .emptyStateTitle {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 900;
      color: var(--light2);
      text-align: center;
      letter-spacing: 0.3px;
    }

    .emptyStateDescription {
      margin-top: 8px;
      font-size: 16px;
      text-align: center;
      color: var(--light2);
    }

    .emptyStateButtonAdd {
      position: absolute;
      bottom: 32px;
      width: 100%;
      left: 0px;
      text-align: center;
    }
  }
}
</style>
