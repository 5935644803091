import axios from 'axios';

import {BACK_ENDPOINT} from '@/store/conf/api';
import {
    ADD_A_WEET,
    DELETE_SHARE_A_WEET,
    ActionUserShareParams,
    GET_ALL_WORKSPACE_WEETS,
    GET_EXPORTED_URL_WEET,
    GET_WEET_PEOPLES,
    GET_WEET_SILENCE,
    LOAD_WEET_FOR_PLAYING,
    MODIFY_SHARE_A_WEET,
    REQUEST_UPGRADE_WORKSPACE_WEET,
    SHARE_A_WEET,
    EDIT_WEET,
    CREATE_WEET_TO_WORKSPACE,
    DELETE_WEET_FROM_WORKSPACE,
    ShareParams, RESET_WORKSPACE_WEET_LIST, MODIFY_OWNER_WEET, REGENERATE_EXPORTED_URL_WEET,
} from '@/store/weet/weetAction';
import {CLEAR_SUBTITLE, CLEAR_TIME_LINE} from '@/store/timeLine/timeLineAction';
import {CLEAR_MEDIA} from '@/store/media/mediaAction';
import {Weet, WeetParams} from '@/store/weet/weetModel';
import Vue from 'vue';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {REFRESH_MY_WEET} from '@/store/myWeet/myWeetAction';
import {GET_LIST_OF_GROUP_CONTACT} from '@/store/contact/contactAction';
import {PaginationWrapper} from '@/store/generic/paginationWrapper';
import store from '@/store';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';

const weetAPI = BACK_ENDPOINT + '/weet';
const shareAPI = BACK_ENDPOINT + '/share';
export default {
    state: {
        weets: {},
        currentWeetPage: 0,
        totalOfWeets: 0,
    },
    mutations: {
        [ADD_A_WEET]: (state: any, weet: Weet) => {
            Vue.set(state.weets, weet.weetID, weet);
        },
        [GET_ALL_WORKSPACE_WEETS]: (state: any, data: PaginationWrapper) => {
            const weets = data.data as Weet[];
            for (const weet of weets) {
                Vue.set(state.weets, weet.weetID, weet);
            }
            state.currentWeetPage = data.page;
            state.totalOfWeets = data.count;
        },
        [RESET_WORKSPACE_WEET_LIST]: (state: any) => {
            state.weets = {};
            state.currentWeetPage = 0;
            state.totalOfWeets = 0;
        },
        [CREATE_WEET_TO_WORKSPACE]: (state: any, weet: Weet) => {
            Vue.set(state.weets, weet.weetID, weet);
        },
        [DELETE_WEET_FROM_WORKSPACE]: (state: any, weetIds: string[]) => {
            for (const weetID of weetIds) {
                Vue.delete(state.weets, weetID);
                state.totalOfWeets--;
            }
        },
    },
    getters: {
        getWeet: (state: any) => (weetID: string): Weet => {
            return state.weets[weetID];
        },
        // Return Weets for the "Manage weets" table
        getAllWeets: (state: any): Weet[] => {
            const arrayOfWeets = Object.values(state.weets) as Weet[];
            return arrayOfWeets.sort((a, b) => {
                return a.title.replace(/\W/g, '').localeCompare(b.title.replace(/\W/g, ''));
            });
        },
        getCurrentWorkspaceWeetPage: (state: any): number => {
            return state.currentWeetPage;
        },
        getTotalOfWeets: (state: any): number => {
            return state.totalOfWeets;
        },
    },
    actions: {
        async [LOAD_WEET_FOR_PLAYING]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                      weetID: string) {
            await dispatch(CLEAR_TIME_LINE);
            await dispatch(CLEAR_SUBTITLE);
            await dispatch(CLEAR_MEDIA);
            // 1 - GET THE LAST VERSION OF WEET
            const weet: Weet = await axios({
                url: weetAPI + '/' + weetID,
                method: 'GET',
            }).then((resp) => {
                const weetFound: Weet = resp.data;
                // now we sort the timeline
                weetFound.timeLine = weetFound.timeLine.sort((a: TimeEvent, b: TimeEvent) => {
                    if (a.time < b.time) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                commit(ADD_A_WEET, weetFound);
                return resp.data;
            }).catch((err) => {
                throw err;
            });

            return weet;
        },
        async [ADD_A_WEET]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                           weet: Weet) {
            commit(ADD_A_WEET, weet);
        },
        async [SHARE_A_WEET]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                             shareParams: ShareParams) {
            return await axios({
                url: shareAPI,
                data: shareParams,
                method: 'POST',
            }).then((resp) => {
                commit(ADD_A_WEET, resp.data);

                if (getters.isAuthenticated) {
                    // refresh my account too
                    dispatch(REFRESH_MY_WEET, resp.data);
                }
                return resp.data;

            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [MODIFY_SHARE_A_WEET]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                    shareParams: ShareParams) {
            return await axios({
                url: shareAPI,
                data: shareParams,
                method: 'PUT',
            }).then((resp) => {
                commit(ADD_A_WEET, resp.data);

                if (getters.isAuthenticated) {
                    // refresh my account too
                    dispatch(REFRESH_MY_WEET, resp.data);
                }
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [MODIFY_OWNER_WEET]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                  actionUserShareParams: ActionUserShareParams) {
            return await axios({
                url: shareAPI + '/owner',
                data: actionUserShareParams,
                method: 'POST',
            }).then((resp) => {
                commit(ADD_A_WEET, resp.data);

                if (getters.isAuthenticated) {
                    // refresh my account too
                    dispatch(REFRESH_MY_WEET, resp.data);
                }
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [DELETE_SHARE_A_WEET]({commit, dispatch}: { commit: any, dispatch: any },
                                    actionUserShareParams: ActionUserShareParams) {
            return await axios({
                url: shareAPI,
                data: actionUserShareParams,
                method: 'DELETE',
            }).then((resp) => {
                commit(ADD_A_WEET, resp.data);
                // refresh my account too
                dispatch(REFRESH_MY_WEET, resp.data);
                // we refresh the group Panel
                dispatch(GET_LIST_OF_GROUP_CONTACT, 0);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [GET_EXPORTED_URL_WEET]({commit, dispatch}: { commit: any, dispatch: any },
                                      {weetID, download = false}: { weetID: string, download: boolean }) {
            return await axios({
                url: weetAPI + '/' + weetID + '/download',
                params: {fromUserInterface: download},
                method: 'GET',
            }).then((resp) => {
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [REGENERATE_EXPORTED_URL_WEET]({commit, dispatch}: { commit: any, dispatch: any },
                                             {weetID}: { weetID: string }) {
            return await axios({
                url: weetAPI + '/' + weetID + '/regenerateExport',
                method: 'PUT',
            }).then((resp) => {
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        // get a weet but no commit on the store
        async [GET_WEET_SILENCE]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                 weetID: string): Promise<Weet> {
            const weet: Weet = await axios({
                url: weetAPI + '/' + weetID,
                method: 'GET',
            }).then((resp) => {
                const weetFound: Weet = resp.data;
                return weetFound;
            }).catch((err) => {
                throw err;
            });
            return weet;
        },
        async [REQUEST_UPGRADE_WORKSPACE_WEET]({
                                                   commit,
                                                   dispatch,
                                                   getters
                                               }: { commit: any, dispatch: any, getters: any },
                                               weetID: string) {
            await axios({
                url: weetAPI + '/' + weetID + '/request',
                method: 'POST',
            }).catch((err) => {
                throw err;
            });
        },
        async [GET_WEET_PEOPLES]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                 weetID: string): Promise<PaginationWrapper> {
            return await axios({
                url: weetAPI + '/' + weetID + '/peoples',
                method: 'GET',
            }).then((resp) => {
                return resp.data;
            }).catch((err) => {
                throw err;
            });
        },

        async [GET_ALL_WORKSPACE_WEETS]({
                                            commit,
                                            dispatch,
                                            getters
                                        }: { commit: any, dispatch: any, getters: any },
                                        weetParams: WeetParams) {
            const ws = store.getters.getSelectedWorkspaceID;
            if (!ws) {
                throw new Error('impossible to find a workspace');
            }
            weetParams.workspaceID = ws;
            return await axios({
                url: `${weetAPI}/workspace/${ws}`,
                method: 'GET',
                params: weetParams,
            }).then(async (resp) => {
                commit(RESET_WORKSPACE_WEET_LIST);
                commit(GET_ALL_WORKSPACE_WEETS, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                commit(RESET_WORKSPACE_WEET_LIST);
                throw err;
            });
        },
        async [EDIT_WEET]({commit, dispatch}: { commit: any, dispatch: any }, {
            weetID,
            name,
            visibilityPolicy
        }: {
            weetID: string,
            name: string | null,
            visibilityPolicy: VisibilityChannelPolicy | null
        }) {
            return await axios({
                url: `${weetAPI}/${weetID}`,
                method: 'PUT',
                data: {name, visibilityPolicy},
            }).then((resp) => {
                commit(CREATE_WEET_TO_WORKSPACE, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [DELETE_WEET_FROM_WORKSPACE]({commit, dispatch}: { commit: any, dispatch: any },
                                           weets: string[]) {
            const ws = store.getters.getSelectedWorkspaceID;
            if (!ws) {
                throw new Error('impossible to find a workspace');
            }
            return await axios({
                url: `${weetAPI}/workspace/${ws}`,
                method: 'DELETE',
                data: {weets: weets},
            }).then(() => {
                commit(DELETE_WEET_FROM_WORKSPACE, weets);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },

    },

};
