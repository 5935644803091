<template>
  <div class="weetAdvancedEditingContainer">
    <div class="titleAdvancedEditing">
      <w-button class="backButton" icon="arrow-left" @click="backToEditing"
                :tooltip="$t('createWeetComponent.advancedEditing.tooltip.back')"
                :color="'light'" size="default">
      </w-button>
      {{ $t('createWeetComponent.advancedEditing.title.cut') }}
    </div>
    <div class="contentAdvancedEditingLayout" v-if="step===0">
      <div class="advancedButtonLine">
        <w-button class="iconButton" icon="comment-outline"
                  :tooltip="$t('createWeetComponent.advancedEditing.tooltip.annotation')"
                  :color="isAnnotationMode?'light1':'outlined'" @click="selectAnnotationMode" :expanded="true">
          {{ $t('createWeetComponent.advancedEditing.button.annotation') }}
        </w-button>
        <w-button class="iconButton" icon="cursor-default-click-outline"
                  :tooltip="$t('createWeetComponent.advancedEditing.tooltip.interaction')"
                  :color="isInteractionMode?'light1':'outlined'" @click="selectInteractionMode" :expanded="true">
          {{ $t('createWeetComponent.advancedEditing.button.interaction') }}
        </w-button>
        <w-button class="iconButton" icon="format-list-bulleted" @click="selectChapterMode" :expanded="true"
                  :tooltip="$t('createWeetComponent.advancedEditing.tooltip.chapter')"
                  :color="isChapterMode?'light1':'outlined'" v-if="!isSpeach">
          {{ $t('createWeetComponent.advancedEditing.button.chapter') }}
        </w-button>
        <w-button class="iconButton" icon="content-cut"
                  :tooltip="$t('createWeetComponent.advancedEditing.tooltip.cut')"
                  :color="isCutMode?'light1':'outlined'" @click="selectCutMode" :expanded="true">
          {{ $t('createWeetComponent.advancedEditing.button.cut') }}
        </w-button>
        <GoToSubtitleWeetButton  v-if="isSubtitleFeatureAvailable" class="iconButton" icon="subtitles-outline" ref="subtitleOpener"
                                :tooltip="$t('createWeetComponent.advancedEditing.tooltip.subtitles')"
                                :color="isSubtitleMode?'light1':'outlined'"
                                :weet="myWeet"
                                :expanded="true">
          {{ $t('createWeetComponent.advancedEditing.button.subtitles') }}
        </GoToSubtitleWeetButton>
        <w-button class="iconButton" icon="microphone-outline"
                  :tooltip="$t('createWeetComponent.advancedEditing.tooltip.voiceOver')"
                  :color="isVoiceOverMode?'light1':'outlined'" @click="selectVoiceOverMode" :expanded="true">
          {{ $t('createWeetComponent.advancedEditing.button.voiceOver') }}
        </w-button>

        <w-button class="iconButton" icon="image-outline" v-if="!isSpeach"
                  :tooltip="$t('createWeetComponent.advancedEditing.tooltip.thumbnail')"
                  :color="isThumbnailMode?'light1':'outlined'" @click="selectThumbnailMode" :expanded="true">
          {{ $t('createWeetComponent.advancedEditing.button.thumbnail') }}
        </w-button>


<!--                  <w-button class="iconButton" icon="box-cutter" :enabled="false" :expanded="true" &ndash;&gt;-->
        <!--                    :tooltip="$t('createWeetComponent.advancedEditing.tooltip.cutter')"-->
        <!--                    :color="isCutterMode?'light1':'outlined'" @click="selectCutterMode">-->
        <!--            {{ $t('createWeetComponent.advancedEditing.button.cutter') }}-->
        <!--          </w-button>-->
        <!--          <w-button class="iconButton" icon="table-merge-cells" :enabled="false" :expanded="true"-->
        <!--                    :tooltip="$t('createWeetComponent.advancedEditing.tooltip.merge')"-->
        <!--                    :color="isMergeMode?'light1':'outlined'" @click="selectMergeMode">-->
        <!--            {{ $t('createWeetComponent.advancedEditing.button.merge') }}-->
        <!--          </w-button>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import store from '@/store';
import WField from '@/components/wrapper/w-field.vue';
import WButton from '@/components/wrapper/w-button.vue';
import ShareVisibilityPolicy from '@/components/share/ShareVisibilityPolicy.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WorkspaceSelection from '@/components/workspace/WorkspaceSelection.vue';
import {AdvancedEditingMode} from '@/enum/AdvancedEditingMode';
import {SELECT_MODE, TIMELINE_ADVANCED_MODIFIED} from '@/store/advancedEditing/advancedEditingAction';
import {Weet} from "@/store/weet/weetModel";
import GoToSubtitleWeetButton from "@/components/actionButton/GoToSubtitleWeetButton.vue";
import {REFRESH_WEET_FOR_EDITING} from "@/store/myWeet/myWeetAction";
import {SEEK_PLAYER, TIMER_PLAYER} from "@/store/timeLine/timeLineAction";
import {isSpeach} from "@/utils/speachUtils";
import {recoverWeet} from "@/utils/weetRecover";


@Component({
  components: {
    GoToSubtitleWeetButton,
    WorkspaceSelection,
    WIcon, ShareVisibilityPolicy, WButton, WField
  },
})
export default class CreateWeetAdvancedEditingStep extends Vue {

  private step: number = 0;


  get myWeet(): Weet | undefined {
    return store.getters.getMyWeet(store.getters.getEditingWeetID);
  }

  get isSpeach():boolean{
    return isSpeach();
  }

  get isCutMode(): boolean {
    return store.getters.isCutMode;
  }
  get isVoiceOverMode():boolean{
    return store.getters.isVoiceOverMode;
  }

  get isCutterMode(): boolean {
    return store.getters.isCutterMode;
  }

  get isChapterMode(): boolean {
    return store.getters.isChapterMode;
  }

  get isMergeMode(): boolean {
    return store.getters.isMergeMode;
  }

  get isThumbnailMode(): boolean {
    return store.getters.isThumbnailMode;
  }

  get isAnnotationMode(): boolean {
    return store.getters.isAnnotationMode;
  }

  get isInteractionMode():boolean{
    return store.getters.isInteractionMode;
  }

  get isSubtitleMode(): boolean {
    return store.getters.isSubtitleMode;
  }

  get isSubtitleFeatureAvailable(): boolean{
    return store.getters.isTranscript
  }

  public async mounted(){
    // recoverWeet
    if(this.myWeet) {
      await recoverWeet(this.myWeet);
    }
    if(this.isSubtitleMode && this.myWeet){
      const subtitleOpener= this.$refs.subtitleOpener as GoToSubtitleWeetButton;
      if(subtitleOpener) {
        subtitleOpener.waitAndGoToSubtitleService();
      }
    }
  }
  private selectCutMode() {
    this.selectMode(AdvancedEditingMode.CUT);
  }
  private selectInteractionMode(){
    this.selectMode(AdvancedEditingMode.INTERACTION);
  }
  private selectAnnotationMode(){
    this.selectMode(AdvancedEditingMode.ANNOTATION);
  }

  private selectVoiceOverMode(){
    this.selectMode(AdvancedEditingMode.VOICE_OVER);
  }

  private selectChapterMode() {
    this.selectMode(AdvancedEditingMode.CHAPTER);
  }

  private selectCutterMode() {
    this.selectMode(AdvancedEditingMode.CUTTER);
  }

  private selectMergeMode() {
    this.selectMode(AdvancedEditingMode.MERGE);
  }

  private selectThumbnailMode() {
    this.selectMode(AdvancedEditingMode.THUMBNAIL);
  }

  private async backToEditing() {
    await this.cancelTimeLineModification();
    this.$emit('back');
  }

  private async cancelTimeLineModification() {
    await store.dispatch(REFRESH_WEET_FOR_EDITING, store.getters.getEditingWeetID);
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, false);
    setTimeout(() => {
      this.changePlayerTime(100);
    });
  }

  private changePlayerTime(time) {
    store.dispatch(SEEK_PLAYER, time);
    store.dispatch(TIMER_PLAYER, time);
  }

  private selectMode(mode: AdvancedEditingMode) {
    store.dispatch(SELECT_MODE, mode);
  }

  public beforeDestroy(){
    store.dispatch(SELECT_MODE, AdvancedEditingMode.NONE);
  }
}
</script>

<style scoped lang="scss">

.weetAdvancedEditingContainer {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;

  .titleAdvancedEditing {
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.3px;
    color: var(--black);
    margin-bottom: 16px;
    // border-bottom: solid 1px var(--light1);

    .backButton {
      margin-right: 16px;
    }
  }

  .contentAdvancedEditingLayout {
    text-align: left;

    .advancedButtonLine {

      .iconButton {
        margin-top: 16px;
      }
    }
  }

}

</style>
