<template>
  <w-tool-tip :insecureHTML="tooltip" size="small" :insecure="true">
    <div class="annotationElement" @click="selectAnnotation" :class="{'edit':isEditing,'selected':isSelect}">
      <div class="timeAnnotation">{{ timeAnnotationHumanTime }}</div>
      <div class="iconAnnotationType">
        <w-icon size="small" :icon="icon" color="dark"/>
      </div>
      <div class="labelAnnotationType">{{ labelType }}</div>
      <div class="deleteAnnotation" @click="deleteAnnotation()">
        <w-icon size="small" icon="delete-outline" color="dark"/>
      </div>
    </div>
  </w-tool-tip>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import WButton from "@/components/wrapper/w-button.vue";
import store from "@/store";
import prettyMilliseconds from "pretty-ms";
import {Annotation, AnnotationType} from "@/store/annotation/annotationModel";
import WIcon from "@/components/wrapper/w-icon.vue";
import WToolTip from "@/components/wrapper/w-toolTip.vue";
import {SELECT_ANNOTATION} from "@/store/annotation/annotationAction";
import {SEEK_PLAYER} from "@/store/timeLine/timeLineAction";
import {getImageURLForSize} from "@/utils/util";


@Component({
  components: {WToolTip, WIcon, WButton},
})
export default class AnnotationListElement extends Vue {

  @Prop()
  private annotation!: Annotation;

  @Prop()
  private weet!: Weet;

  get timeAnnotationHumanTime(): string {
    return this.getHumanTime(this.annotation.time);
  }

  get icon(){
    if(this.annotation.type===AnnotationType.TEXT){
      return 'text'
    }else if(this.annotation.type===AnnotationType.IMAGE){
      return 'image-outline'
    }else if(this.annotation.type===AnnotationType.INTERACTION){
      return 'message-outline'
    }
    return '';
  }

  get tooltip():string{
    if(this.annotation) {
      if (this.annotation.annotationCard && this.annotation.annotationCard.exportStaticUrl) {
        return "<img alt='previewAnnotation' src='" + getImageURLForSize(this.annotation.annotationCard.exportStaticUrl, 250) + "'/>";
      } else if (this.annotation.exportStaticUrl) {
        if (this.annotation.type === AnnotationType.INTERACTION) {
          return "<img alt='previewAnnotation' width='30' src='" + getImageURLForSize(this.annotation.exportStaticUrl, 250) + "'/>";
        } else {
          return "<img alt='previewAnnotation' src='" + getImageURLForSize(this.annotation.exportStaticUrl, 250) + "'/>";
        }
      } else {
        return this.annotation.textContent;
      }
    }
    return "";
  }

  get labelType(): string {
    if(this.annotation.type===AnnotationType.TEXT){
      return this.annotation.textContent?.replaceAll("<br>",'') || this.$t('createWeetComponent.advancedEditing.annotationMode.type.text').toString();
    }else if(this.annotation.type===AnnotationType.IMAGE){
      return  this.$t('createWeetComponent.advancedEditing.annotationMode.type.image').toString();
    } else if(this.annotation.type===AnnotationType.INTERACTION){
      if(this.annotation.annotationCard.title){
        return this.annotation.annotationCard.title;
      }else if(this.annotation.annotationCard.imageUrl){
        return  this.$t('createWeetComponent.advancedEditing.annotationMode.type.image').toString();
      }else if( this.annotation.annotationCard.description){
        return this.$t('createWeetComponent.advancedEditing.annotationMode.type.text').toString();
      }else if(this.annotation.annotationCard.interactionUrl){
        return this.annotation.annotationCard.interactionLabel;
      }
      else {
        return this.$t('createWeetComponent.advancedEditing.annotationMode.type.interaction').toString();
      }
    }
    return '';
  }

  get isEditing() {
    return this.annotation.annotationID == store.getters.getAnnotationSelected?.annotationID
  }

  get isSelect() {
    var timePlayer = store.getters.getTimerPlayer;
    if (timePlayer > this.annotation.time && timePlayer < this.annotation.time + this.annotation.duration) {
      return true
    } else {
      return false;
    }
  }


  private getHumanTime(val: number): string {
    if (val < 1000) {
      return '0:00';
    } else {
      return prettyMilliseconds(val, {colonNotation: true, secondsDecimalDigits: 0});
    }
  }

  private selectAnnotation() {
    store.dispatch(SELECT_ANNOTATION, this.annotation);
    store.dispatch(SEEK_PLAYER, this.annotation.time)
  }

  private deleteAnnotation() {
    this.$emit('delete',this.annotation);
  }


}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.annotationElement {
  width: 100%;
  margin-top: 8px;
  height: 32px;
  background: var(--light1);
  border-radius: 8px;
  display: flex;
  color: var(--dark);
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--light1);

  .labelAnnotationType {
    flex: 1;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .iconAnnotationType {
    margin-right: 8px;
  }

  .timeAnnotation {
    margin-right: 4px;
    font-size: 16px;
    font-weight: 900;
    color: var(--dark);
  }

  .deleteAnnotation {
    &:hover {
      transform: scale(1.2);
    }
  }

  &.selected {
    border: 1px solid var(--dark);
  }

  &.edit {
    border: 1px solid var(--light2);
    background: var(--light1);
    box-shadow: 2px 2px 6px var(--light2);
  }

  &:hover {
    border: 1px solid var(--light2);
  }

}
</style>
